export const customizedTheme = {
    token: {
        colorPrimary: '#e9ac50',
        fontSize: 17,
        borderRadius: '20px',
        lineHeight: 1.5715
    },
    components: {
        Layout: {
            colorBgHeader: '#FFFFFF'
        },
        Menu: {
            colorItemTextHover: '#e9ac50',
            colorItemBgHover: 'transparent',
            radiusItem: 0
        }
    }

};