import React from 'react';
import { Row, Col, Typography, Carousel } from 'antd';
import Cluar from '../../../common/Cluar';

import './index.less';
import Title from 'antd/es/typography/Title';
const { Paragraph } = Typography;
function Item({ section, type, image, image_title, image_alt, title, link, index, content }) {
  let layout = null;
  if (type === 'home-client-list') {
    layout = (
      <Col xs={12} sm={8} md={4} lg={4}>
        <a className={`listing_item--${type}`} href={link} alt={title}>
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} />
        </a>
      </Col>
    );

  } else if (type === 'home-service-list') {
    layout = (
      <Col className={`listing_item--${type}`} xs={24} sm={12} md={12} lg={6}>
        <div>
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} />
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </Col>
    );
  } else if (type === 'product-list') {
    layout = (
      <Row className={`listing__${type}--item`} align="middle">
        <Col xs={24} lg={12}>
          <img src={`/images/${section}/${image}`} alt={image_alt} title={image_title} />
        </Col>
        <Col xs={24} lg={12} dangerouslySetInnerHTML={{ __html: content }} />
      </Row>
    );
  } else if (type === 'about-portfolio-list') {
    layout = (
      <Col className={`listing__${type}--item`} xs={24} sm={24} md={12} lg={12}>
        <div>
          <div
            className="img"
            style={{ backgroundImage: `url(/images/${section}/${image})`, cursor: 'pointer' }}
            onClick={() => window.location.href = link}
          />
          <h2 style={{ marginTop: '16px !important' }}>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <a alt={title} href={link}>{Cluar.plainDictionary('KNOW-MORE')}</a>
        </div>
      </Col>
    );
  } else if (type === 'about-solution-list-veks') {
    layout =
      <Col span={24} className="listing__about-solution-list--item">
        <Row justify="space-between" gutter={[12, 0]} align={'middle'}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div>
              <Title level={3}
                style={{
                  fontFamily: "Roboto"
                }}>
                {title}
              </Title>
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: content }}
              style={{
                fontSize: "16px"
              }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Carousel autoplay>
              {/* <div>
                <img
                  src="/images/agendamento container.png"
                  style={{
                    width: "100%"
                  }}
                />
              </div> */}
              <div>
                <img
                  src="/images/veks-container.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src={`/images/${section}/${image}`}
                  alt={image_alt}
                  title={image_title}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            </Carousel>
          </Col>
        </Row>
        <Col className="know-more"><a alt={title} href={link} >{Cluar.plainDictionary('KNOW-MORE')}</a></Col>
      </Col>
  } else if (type === 'about-solution-list-netuno') {
    layout =
      <Col span={24} className="listing__about-solution-list--item--netuno">
        <Row justify={"space-between"} gutter={[12, 0]} align={'middle'}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}
            style={{
              padding: "0px",
            }}>
            <Carousel autoplay>
              <div>
                <img
                  src="/images/node-netuno.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src={`/images/${section}/${image}`}
                  alt={image_alt}
                  title={image_title}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/java-netuno.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>

            </Carousel>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div>
              <Title level={3}
                style={{
                  fontFamily: "Roboto",
                }}>
                {title}
              </Title>
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: content }}
              style={{
                fontSize: "16px",
              }} />
          </Col>
        </Row>
        <Col className="know-more"><a alt={title} href={link} className="button-know-more">{Cluar.plainDictionary('KNOW-MORE')}</a></Col>
      </Col>
  } else if (type === 'about-solution-list-crm') {
    layout =
      <Col span={24} className="listing__about-solution-list--item--crm">
        <Row justify={"space-between"} gutter={[12, 0]} align={'middle'}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}
            style={{
              padding: "0px",
            }}>
            <Carousel autoplay>
              <div>
                <img
                  src={`/images/${section}/${image}`}
                  alt={image_alt}
                  title={image_title}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/CRM-2.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/CRM-3.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/CRM-4.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            </Carousel>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div>
              <Title level={3}
                style={{
                  fontFamily: "Roboto",
                }}>
                {title}
              </Title>
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: content }}
              style={{
                fontSize: "16px",
              }} />
          </Col>
        </Row>
        <Col className="know-more"><a alt={title} href={link} className="button-know-more">{Cluar.plainDictionary('KNOW-MORE')}</a></Col>
      </Col>
  } else if (type === 'about-solution-list-ask2me') {
    layout =
      <Col span={24} className="listing__about-solution-list--item">
        <Row justify={"space-between"} gutter={[12, 0]} align={'middle'}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div>
              <Title level={3}
                style={{
                  fontFamily: "Roboto"
                }}>
                {title}
              </Title>
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: content }}
              style={{
                fontSize: "16px"
              }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Carousel autoplay>
              <div>
                <img
                  src={`/images/${section}/${image}`}
                  alt={image_alt}
                  title={image_title}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/Ask2Me-2.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/Ask2Me-3.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src="/images/Ask2Me-4.jpg"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            </Carousel>
          </Col>
        </Row>
        <Col className="know-more"><a alt={title} href={link} >{Cluar.plainDictionary('KNOW-MORE')}</a></Col>
      </Col>
  } else if (type === 'about-solution-list-formation') {
    layout =
      <Col span={24} className="listing__about-solution-list--item">
        <Row justify={"space-between"} gutter={[12, 0]} align={'middle'}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <div>
              <Title level={3}
                style={{
                  fontFamily: "Roboto"
                }}>
                {title}
              </Title>
            </div>
            <h1 dangerouslySetInnerHTML={{ __html: content }}
              style={{
                fontSize: "16px"
              }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Carousel autoplay>
              <div>
                <img
                  src="/images/contact fixo.png"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
              <div>
                <img
                  src={`/images/${section}/${image}`}
                  alt={image_alt}
                  title={image_title}
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            </Carousel>
          </Col>
        </Row>
      </Col>
  } else {
    layout = (
      <li className="listing_item" style={{ backgroundImage: `url(/images/${section}/${image})` }}>
        <div className={`item__${type}`}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </li>
    );
  }
  return (
    layout
  );
}

export default Item;