import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Alert, notification, Checkbox } from 'antd';
import _service from '@netuno/service-client';
import Cluar from '../../../common/Cluar';

import './index.less';

const { TextArea } = Input;

function ContactForm({ title }) {
  const validateMessages = {
    required: Cluar.plainDictionary('contact-form-validate-message-required'),
    types: {
      email: Cluar.plainDictionary('contact-form-validate-message-email')
    }
  };

  const layout = {
    rowGutter: { gutter: [25, 0] },
    labelCol: { span: 'hide' }
  };

  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setLoading(true);
    _service({
      url: "service/list",
      success: (res) => {
        setLoading(false);
        if (res.json) {
          setServices(res.json.services);
        }
      },
      fail: (err) => {
        setLoading(false);
        console.error(err);
      }
    });
  }, []);

  const handleFinish = (values) => {
    console.log(values)
    values.contactForm.locale = window.localStorage.getItem('locale');
    setLoading(true);
    const fail = () => {
      setLoading(false);
      notification.error({
        message: title,
        description: Cluar.plainDictionary('contact-form-fail'),
        top: 100
      });
    };
    _service({
      url: "contact",
      method: 'POST',
      data: values.contactForm,
      success: (response) => {
        if (response.json && response.json.result === true) {
          setLoading(false);
          setSent(true);
          notification.success({
            message: title,
            description: Cluar.plainDictionary('contact-form-success'),
            top: 100
          });
        } else {
          fail();
        }
      },
      fail: (e) => {
        console.log("ContactForm failed:", e);
        fail();
      }
    });
  };
  return (
    <section className="contact-form">
      <hr />
      <Form labelCol={layout.labelCol} validateMessages={validateMessages} onFinish={handleFinish}>
        <h2>{title}</h2>
        <h1>{Cluar.plainDictionary('contact-form-description')}</h1>
        <Row {...layout.rowGutter}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-form-name')} name={['contactForm', 'name']} rules={[{ required: true }]}>
              <Input placeholder={Cluar.plainDictionary('contact-form-name')} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-form-email')} name={['contactForm', 'email']} rules={[{ required: true, type: 'email' }]}>
              <Input placeholder={Cluar.plainDictionary('contact-form-email')} />
            </Form.Item>
          </Col>
        </Row>
        <Row {...layout.rowGutter}>
          <Col span={24}>
            <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-form-telephone')} name={['contactForm', 'telephone']} rules={[{ required: true }]}>
              <Input placeholder={Cluar.plainDictionary('contact-form-telephone')} />
            </Form.Item>
          </Col>
        </Row>
        <Row {...layout.rowGutter}>
          <Col span={24}>
            <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-form-company')} name={['contactForm', 'company']} rules={[{ required: true }]}>
              <Input placeholder={Cluar.plainDictionary('contact-form-company')} />
            </Form.Item>
          </Col>
        </Row>
        <Row {...layout.rowGutter}>
          <Col span={24}>
            <h3>{Cluar.plainDictionary('contact-form-service')}</h3>
            <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-form-service')} name={['contactForm', 'services']} valuePropName="checked">
              <Checkbox.Group>
                <Row>
                  {services.map((serviceItem) => (
                    <Col span={12}>
                      <Checkbox value={serviceItem.uid}>
                        {Cluar.plainDictionary(serviceItem.code)}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row {...layout.rowGutter}>
          <Col span={24}>
            <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-form-message')} name={['contactForm', 'message']} rules={[{ required: true, message: 'Descrição é de preenchimento obrigatório.' }]} >
              <TextArea autoSize={{ minRows: 3 }} placeholder={Cluar.plainDictionary('contact-form-message')} />
            </Form.Item>
          </Col>
        </Row>
        <Row {...layout.rowGutter}>
          <Col span={24}>
            <Form.Item wrapperCol={24}>
              {!sent &&
                <Button htmlType="submit" type="primary" block {...{ loading }}>{Cluar.plainDictionary('contact-form-send')}</Button>}
              {sent && <Alert message={Cluar.plainDictionary('contact-form-alert-sent')} type="success" showIcon />}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </section>
  );
}

export default ContactForm;