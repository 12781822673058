import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';
import {
  Route,
  Link
} from "react-router-dom";
import Cluar from '../../common/Cluar';
import Builder from '../../common/Builder';

import logo from '../../assets/images/logo.png'

import './index.less';

const { Header } = Layout;
const { SubMenu } = Menu;

function BaseHeader() {
  const storageLocale = window.localStorage.getItem('locale');
  if (storageLocale == null) {
    window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
  } else {
    Cluar.changeLanguage(storageLocale);
  }

  const [burgerMenu, setBurgerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('main');
  const [pageOnTop, setPageOnTop] = useState(document.documentElement.scrollTop === 0 ? true : false);
  const [locale, setLocale] = useState(Cluar.currentLanguage().locale);


  const handleMenuClick = (selectMenu) => {
    setBurgerMenu(false);
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  //const topDivider = [];

  const menuLanguages = [];
  const menu = [];
  const subMenuKeys = [];
  const routes = [];
  for (const language of Cluar.languages()) {
    if (!Cluar.pages()[language.code]) {
      continue;
    }

    if (language.code !== Cluar.currentLanguage().code) {
      menuLanguages.push({
        key: language.code,
        label: language.description,
        onClick: () => {
          Cluar.changeLanguage(language.locale);
          window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
          window.location.href = `/${language.locale}/`;
        }
      });
    }

    const buildMenu = (page, level) => {
      if (page.menu && language.code === Cluar.currentLanguage().code) {
        const key = `${page.link}`;
        if (Cluar.pages()[language.code].find((p) => p.menu && p.parent === page.link)) {
          subMenuKeys.push(key);
          return {
            key,
            popupClassName: `header-submenu ${level + 1}`,
            label: (
              <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                {page.title}
              </Link>
            ),
            children: Cluar.pages()[language.code].filter((p) => p.menu && p.parent === page.link).map((p) => buildMenu(p, level + 1))
          };
        } else {
          if (level == 1) {
            return {
              key,
              label: (
                <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)} className="sub-menu-item-wrapper">
                  <p className="sub-menu-item-title">{page.title}</p>
                  <p className="sub-menu-item-description">{page.description}</p>
                </Link>
              )
            }
          }

          return {
            key,
            label: (
              <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                {page.title}
              </Link>
            )
          }
        }
      }
      return null;
    };

    const subroutes = [];

    for (const page of Cluar.pages()[language.code]) {
      if (page.menu && page.parent === "" && language.code === Cluar.currentLanguage().code) {
        menu.push(
          buildMenu(page, 0)
        );
      }
      subroutes.push(
        <Route exact key={`/${language.locale}${page.link}`} path={`/${language.locale}${page.link}`} render={() => {
          return <Builder page={page} />;
        }} />
      );
    }
    routes.push(
      <Route key={`/${language.locale}/`} path={`/${language.locale}/`}>
        {subroutes}
      </Route>
    );
  }

  useEffect(() => {
    const onScroll = e => {
      e.target.documentElement.scrollTop === 0 ? setPageOnTop(true) : setPageOnTop(false);
    };
    window.addEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="page-header">
      <Header className={classNames({ 'header-burger-open': burgerMenu, 'page-on-top': pageOnTop })}>
        <div className="logo">
          <Link to={`/${Cluar.currentLanguage().locale}/`} onClick={() => handleMenuClick('/')}>
            <img alt="logo" src={logo} />
          </Link>
        </div>
        <div className={
          classNames({
            'menu': true
          })
        }>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={[activeMenu]}
            selectedKeys={[activeMenu]}
            items={menu}
          />
        </div>
        <div className={
          classNames({
            'menu': true,
            'menu-burger': true,
            'menu-burger-open': burgerMenu
          })
        }>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[activeMenu]}
            selectedKeys={[activeMenu]}
            openKeys={subMenuKeys}
            items={menu}
          />
        </div>

        <div className="menu-burger-button">
          <Burger isOpen={burgerMenu} onClick={() => { setBurgerMenu(!burgerMenu); }} />
        </div>

        <Menu
          theme="light"
          className="menu-languages"
          mode={'horizontal'}
          defaultSelectedKeys={[activeMenu]}
          selectedKeys={[activeMenu]}
          items={[
            {
              key: "menu-languages",
              label: Cluar.currentLanguage().code,
              icon: <GlobalOutlined />,
              children: menuLanguages
            }
          ]}
        />
      </Header>

    </div>
  );
}

export default BaseHeader;
